import React, { useContext, useEffect, useRef, useState } from "react";
import { baseUri, socketBaseUri } from "src/config/config";
import {
  _get_admin_time_zone_localStorage,
  _get_card_localStorage,
  _get_coach_from_localStorage,
  _get_consultant_from_localStorage,
  _get_consultant_id_from_localStorage,
  _get_team_type_from_localStorage,
  _get_user_from_localStorage,
  _get_graphics_from_localStorage,
  set_graphics_data_in_localStorage,
  _get_general_data_from_localStorage,
  _get_consultant_data_from_localStorage,
  _get_coach_id_from_localStorage,
  _get_project_data_from_localStorage,
  _get_dynamic_caoch_id_from_localStorage,
  _get_nav_items_from_localStorage,
  _get_coach_list_from_localStorage,
  _get_domain_data_from_localStorage,
  _get_coach_time_zone_localStorage,
  _get_default_content_from_localStorage,
  _get_defaultLangugae_from_localStorage,
  _get_navbar_content_from_localStorage,
  _get_client_navbar_content_from_localStorage,
  _get_sandbox_coach_card_localStorage,
} from "../DAL/localstorage/LocalStorage";

import io from "socket.io-client";
import { consultantProfileApi } from "src/DAL/Login/Login";
import { getDefaultNavProgram } from "src/DAL/WebsitePages/WebsiteProgrammes";
import { consultantDataApi } from "src/DAL/SiteSetting/siteSetting";
import { project_configuration_api } from "src/DAL/WebsitePages/WebsitePages";
import moment from "moment";
import { get_date_with_user_time_zone } from "src/utils/constants";
import {
  currencyApi,
  currencyApicourses,
} from "src/DAL/WebsitePages/paymentPlan";
import { get_coach_language_list } from "src/DAL/Languages/Languages";
import { useSnackbar } from "notistack";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";

const CreateContentSetting = React.createContext();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_coach_time_zone_localStorage = _get_coach_time_zone_localStorage();
const get_user = _get_user_from_localStorage();
const get_card = _get_card_localStorage();
const get_sandbox_coach_card = _get_sandbox_coach_card_localStorage();
const get_consultant = _get_consultant_from_localStorage();
const get_coach = _get_coach_from_localStorage();
const get_consultant_id = _get_consultant_id_from_localStorage();
const get_team_type = _get_team_type_from_localStorage();
const coach_graphics_data = _get_graphics_from_localStorage();
const get_general_data = _get_general_data_from_localStorage();
const get_consultant_data = _get_consultant_data_from_localStorage();
const get_coach_id = _get_coach_id_from_localStorage();
const get_project_data = _get_project_data_from_localStorage();
const get_domain_data = _get_domain_data_from_localStorage();
const get_dynamic_coach = _get_dynamic_caoch_id_from_localStorage();
const get_nav_items = _get_nav_items_from_localStorage();
const get_coach_list = _get_coach_list_from_localStorage();
const get_default_content = _get_default_content_from_localStorage();
const get_navbar_content = _get_navbar_content_from_localStorage();
const get_client_navbar_content =
  _get_client_navbar_content_from_localStorage();
const get_default_language = _get_defaultLangugae_from_localStorage();
// socket-initialization
const socketConfig = {
  reconnectionDelayMax: 10000,
};

let socket = { on: () => {}, emit: () => {}, off: () => {} };
if (get_consultant_data?.coach._id) {
  // toturn off the socket
  socket = io(
    socketBaseUri + `?user_id=${get_consultant_data.coach._id}&role=coach`,
    {
      ...socketConfig,
    }
  );
}

export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const START_SOCKET_FORCEFULLY = (user_id) => {
    if (Object.keys(socket).length > 0) {
      // check if the connection request from same user id
      let uri = socket.io?.uri;
      let is_same_user = uri?.includes(user_id);

      // --------------------------------------------------------------
      // if socket is not connected. Just connect the socket with user id

      if (!socket.connected) {
        socket = io(
          socketBaseUri + `?user_id=${user_id}&role=coach`,
          socketConfig
        );
        setIsConnected(true);
        return;
      }

      // --------------------------------------------------------------

      // if socket is connected but upcoming user id is for new user
      // close old connection and open new connection with new id.

      if (socket.connected && !is_same_user) {
        socket.disconnect();
        socket = io(
          socketBaseUri + `?user_id=${user_id}&role=coach`,
          socketConfig
        );

        setIsConnected(true);
      }

      // --------------------------------------------------------------
    } else {
      socket = io(
        socketBaseUri + `?user_id=${user_id}&role=coach`,
        socketConfig
      );
    }
  };

  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };

  const inlineEditorRef = useRef(null);

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const [coachTimeZone, setCoachTimeZone] = useState(
    get_coach_time_zone_localStorage
  );
  const [generalData, setGeneralData] = useState(get_general_data);
  const [consultantData, setConsultantData] = useState(get_consultant_data);
  const [dynamicCoachId, setDynamicCoachId] = useState(get_dynamic_coach);
  const [coachId, setCoachId] = useState(get_coach_id);
  const [coachGraphics, setCoachGraphics] = useState(coach_graphics_data);
  const [projectData, setProjectData] = useState(get_project_data);
  const [domainData, setDoaminData] = useState(get_domain_data);
  const [stripeKeysExist, setStripeKeysExist] = useState(false);

  const [navItems, setNavItems] = useState(get_nav_items);
  const [coachList, setCoachList] = useState(get_coach_list);

  //
  const [userInfo, setUserInfo] = useState(get_consultant_data?.coach);
  const [cardDetail, setCardDetail] = useState(get_card);
  const [sandboxCachCard, setSandboxCoachCard] = useState(
    get_sandbox_coach_card
  );

  const [consultantInfo, setConsultantInfo] = useState(get_consultant);
  const [coachInfo, setCoachInfo] = useState(get_coach);
  const [consultantId, setConsultantId] = useState(get_consultant_id);
  const [teamType, setTeamType] = useState(get_team_type);
  const [teamCoachId, setTeamCoachId] = useState(get_user?.action_id);
  const [memberType, setMemberType] = useState(get_user?.team_type);
  const [currencyList, setCurrencyList] = useState([]);
  const [courseCurrencyList, setCourseCurrencyList] = useState([]);

  const [isConnected, setIsConnected] = useState(socket.connected);

  const [openCardModal, setOpenCardModal] = useState(false);
  const [stripePaymentMode, setStripePaymentMode] = useState("live");
  const [open, setOpen] = useState(false); // state to handle notifiaction popup
  //dummy data for default content
  const [defaultContent, setDefaultContent] = useState(get_default_content);

  const [navbarContent, setNavbarContent] = useState(get_navbar_content);
  const [clientNavbarContent, setClientNavbarContent] = useState(
    get_client_navbar_content
  );

  const enqueueSnackbar = useSnackbar();
  const [defaulLanguagae, setDefaultLanguage] = useState(get_default_language);
  const [isLnaguageChange, setIsLanguageChange] = useState(false);
  const [languageListing, setLanguageListing] = useState([]);
  const [isLanguageUpdating, setIsLanguageUpdating] = useState(false);
  const [isInteractingWithEditor, setInteractingWithEditor] = useState(false);

  /* ------------------------------------------------------
  ------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };

  const handleCoachTimeZone = (val) => {
    setCoachTimeZone(val);
  };

  const handleSetUserInfo = (val) => {
    // setUserInfo(val);
  };
  const handleSetCard = (val) => {
    setCardDetail(val);
  };
  const handleSetCoachCard = (val) => {
    setSandboxCoachCard(val);
  };
  const handleConsultantInfo = (val) => {
    // console.log(val, "sajgdsaud");
    setConsultantInfo(val);
  };
  const handleCoachList = (val) => {
    setCoachList(val);
  };
  const handleGeneralData = (val) => {
    // console.log(val, "sajgdsaud");
    setGeneralData(val);
  };
  const handleProjectData = (val) => {
    // console.log(val, "sajgdsaud");
    setProjectData(val);
  };
  const handleDomainData = (val) => {
    // console.log(val, "sajgdsaud");
    setDoaminData(val);
  };
  const handleCoachInfo = (val) => {
    setCoachInfo(val);
  };

  const handleConsultantData = (val) => {
    setConsultantData(val);
    setUserInfo(val?.coach);
  };
  const handleRemoveConsultantMetaData = () => {
    let configurationObj = {
      ...consultantData.configuration,
      meta_data: {
        ...consultantData.configuration.meta_data,
        meta_description: "",
        meta_title: "",
        favicon: {
          ...consultantData.configuration.meta_data.favicon,
          thumbnail_1: "",
        },
      },
    };
    setConsultantData((prev) => ({
      ...prev,
      configuration: configurationObj,
    }));
  };
  const handleDynamicCoach = (val) => {
    setDynamicCoachId(val);
  };
  const handleNavItmes = (val) => {
    setNavItems(val);
  };
  const handleCoachId = (val) => {
    setCoachId(val);
  };

  const handleGraphics = (val) => {
    set_graphics_data_in_localStorage(val);
    setCoachGraphics(val);
  };

  const getCoachLanguageListing = async () => {
    try {
      let result = await get_coach_language_list(coachId);
      if (result.code === 200) {
        setLanguageListing(result.languages_list);
        setIsLanguageUpdating(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Error fetching languages", { variant: "error" });
    }
  };

  useEffect(() => {
    getCoachLanguageListing();
  }, []);

  // console.log(handleCoachInfo, "gycsgfusdguifhduisfh");
  const getProfile = async () => {
    const result = await consultantProfileApi(userInfo?._id);
    if (result.code == 200) {
      localStorage.setItem(`user_data`, JSON.stringify(result.consultant));
      localStorage.setItem(
        `plan_currency`,
        JSON.stringify(result.plan_currency)
      );
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      handleSetUserInfo(result.consultant);
      handleAdminTimeZone(result.admin_time_zone);
    }
  };
  const getCardDetail = async () => {
    const postData = {
      coach_id: dynamicCoachId,
    };
    const result = await consultantDataApi(postData);

    if (result.code === 200) {
      console.log(result?.configuration, "resultOfCachCard");
      // setCoachData(result.default_setting);
      // handleCoachInfo(result.default_setting);

      if (result?.configuration && result?.configuration.coach_card) {
        localStorage.setItem(
          `card_data`,
          JSON.stringify(result?.configuration.coach_card)
        );
        setCardDetail(result?.configuration.coach_card);
      }
      if (result?.configuration && result?.configuration.sandbox_coach_card) {
        localStorage.setItem(
          `sandbox_coach_card_data`,
          JSON.stringify(result?.configuration.sandbox_coach_card)
        );
        setSandboxCoachCard(result?.configuration.sandbox_coach_card);
      }
    }
  };

  const getUserInfoDetails = async () => {
    const postData = {
      coach_id: dynamicCoachId,
    };
    const result = await consultantDataApi(postData);
    console.log(result, "configurationApiResponse");
    if (result.code === 200) {
      setIsLanguageChange(false);
      localStorage.setItem("consultantdata", JSON.stringify(result));
      localStorage.setItem(
        "coachId",
        JSON.stringify(result?.configuration.coach_id)
      );
      localStorage.setItem("coachList", JSON.stringify(result.coach_array));
      localStorage.setItem(
        `admin_time_zone`,
        JSON.stringify(result.configuration.admin_timezone)
      );
      localStorage.setItem(
        `coach_time_zone`,
        JSON.stringify(result.configuration.time_zone)
      );
      const coachObject = result.coach_array.find(
        (coach) => coach.coach_id === dynamicCoachId
      );
      const navItems = coachObject.nav_items;
      localStorage.setItem("navItems", JSON.stringify(navItems));
      localStorage.setItem(
        "defaulLanguagae",
        JSON.stringify(result?.configuration?.default_language)
      );
      // let tempDefaultContentObj = result?.coach_static_content;
      // tempDefaultContentObj = {
      //   ...tempDefaultContentObj,
      //   automation: {
      //     ...tempDefaultContentObj.automation,
      //     add_poratl_dialog: {
      //       ...tempDefaultContentObj.automation.add_poratl_dialog,
      //       no_data_found: "No Portals Exist to apply Automation.",
      //     },
      //     add_programme_drip_dialog: {
      //       ...tempDefaultContentObj.automation.add_programme_drip_dialog,
      //       no_programme_found: "No Programmes Exist to apply Automation.",
      //       no_drip_found: "No Drips Exist to apply Automation.",
      //     },
      //   },

      //   analytics: {
      //     ...tempDefaultContentObj.analytics,
      //     overview: {
      //       ...tempDefaultContentObj.analytics.overview,
      //       heading_title_info_tooltip:
      //         "The Overview section highlights key user metrics, including total users, active users, new users, and average engagement time. Data updates every 24 hours to provide a snapshot of user activity and engagement trends.",
      //       total_user_info_tooltip:
      //         "The cumulative count of all unique users who have interacted with your website within a specific timeframe.",
      //       active_user_info_tooltip:
      //         "The number of users currently engaging with your website in real time or within the chosen reporting period.",
      //       new_user_info_tooltip:
      //         "The count of first-time visitors or users who interacted with your website for the first time during the reporting period.",
      //       average_engagement_time_info_tooltip:
      //         "The average duration that users actively spent on your website, excluding periods of inactivity. This reflects user interaction and engagement quality.",
      //     },
      //     realtime_report: {
      //       ...tempDefaultContentObj.analytics.realtime_report,
      //       heading_title_info_tooltip:
      //         "The Realtime Report shows live data on active users, their locations, and current interactions on your website. It helps track real-time user behavior and the impact of ongoing campaigns.",
      //       event_count_info_tooltip:
      //         "The Events section shows which screens your audience is visiting and how they are interacting with your content. It also displays the event counts, helping you track user engagement with specific screens in real-time.",
      //     },
      //     events: {
      //       ...tempDefaultContentObj.analytics.events,
      //       heading_title_info_tooltip:
      //         "The Events Table displays the screens your audience is visiting, along with key engagement metrics for each screen, and updates every 24 hours.",
      //       event_name_info_tooltip:
      //         "The name of the specific screen or action being tracked, such as a page or feature that users are visiting.",
      //       active_user_info_tooltip:
      //         "The number of unique users currently interacting with the screen or event during the reporting period.",
      //       new_user_info_tooltip:
      //         "The count of first-time visitors who interacted with the screen, helping you understand how many new users are exploring this content.",
      //       engaged_session_info_tooltip:
      //         "The number of sessions in which users actively engaged with the screen, indicating how many times the screen was viewed in an engaging manner.",
      //       average_engagement_time_info_tooltip:
      //         "The average duration users spent on the screen, reflecting how long they interacted with the content on that particular screen.",
      //       engagement_rate_info_tooltip:
      //         "The percentage of sessions that involved interaction with the screen, showing how often users engage with this specific content relative to all sessions.",
      //     },
      //     event_details: {
      //       ...tempDefaultContentObj.analytics.event_details,
      //       heading_title_info_tooltip:
      //         "The Event Details provides a deeper look into user interactions with specific screens, showing key metrics like total users, active users, new users, and average engagement time. It helps you understand how users are engaging with particular screens on your website.",
      //       total_user_info_tooltip:
      //         "The overall number of unique users who interacted with the event during the reporting period.",
      //       active_user_info_tooltip:
      //         "The count of users actively engaging with the event at any given time during the reporting period.",
      //       new_user_info_tooltip:
      //         "The number of first-time users who visits the specific screen.",
      //       average_engagement_time_info_tooltip:
      //         "The average amount of time users spent interacting with the screen, reflecting the depth of engagement.",
      //     },
      //     demographic_report: {
      //       ...tempDefaultContentObj.analytics.demographic_report,
      //       heading_title_info_tooltip:
      //         "The Demographic Report displays the countries your audience is coming from, along with key engagement metrics for each country. The data updates every 24 hours.",
      //       country_name_info_tooltip:
      //         "The name of the country where the users are located.",
      //       active_user_info_tooltip:
      //         "The number of unique users from that country interacting with your website during the reporting period.",
      //       new_user_info_tooltip:
      //         "The count of first-time users from that country who interacted with your website.",
      //       engaged_session_info_tooltip:
      //         "The number of sessions where users from that country actively engaged with your content.",
      //       average_engagement_time_info_tooltip:
      //         "The average time users from that country spent engaging with your website or app.",
      //       engagement_rate_info_tooltip:
      //         "The percentage of sessions from users in that country that involved engagement, showing how often users from that country interacted with your content relative to all sessions.",
      //     },
      //     platform_details: {
      //       ...tempDefaultContentObj.analytics.platform_details,
      //       heading_title_info_tooltip:
      //         "The Platform Details Report shows the browsers your audience is using to access your website or app, along with key engagement metrics for each browser. The data updates every 24 hours.",
      //       browser_name_info_tooltip:
      //         "The name of the browser users are using, such as Chrome, Firefox, Safari, etc.",
      //       active_user_info_tooltip:
      //         "The number of unique users using that browser to interact with your website or app during the reporting period.",
      //       new_user_info_tooltip:
      //         "The count of first-time users accessing your website or app through that browser.",
      //       engaged_session_info_tooltip:
      //         "The number of sessions where users actively engaged with your content using that browser.",
      //       engagement_rate_info_tooltip:
      //         "The percentage of sessions from users using that browser that involved engagement, indicating how often users on that browser interacted with your content.",
      //     },
      //   },
      //   setting: {
      //     ...tempDefaultContentObj.setting,
      //     // new pairsss
      //     plan_configuration: {
      //       setting_content: {
      //         title: "Plan Default Configurations",
      //         info_tooltip:
      //           "In the Default Plan Configuration screen, you can set the default currency, payment gateway, and configurations for Portals, Programmes/Drip, and Agreement Forms. These settings will automatically apply to all future plans.",
      //         payment_gateway_label: "Payment Gateway",
      //         payment_gateway_stripe: "Stripe",
      //         payment_gateway_paypal: "Paypal",
      //         currency_label: "Currency",
      //         portals_label: "Portals",
      //         programmes_drip: "Programmes / Drip",
      //         option_programmes: "Programmes",
      //         option_drip: "Drip",
      //         select_programmes: "Select Programmes",
      //         select_drip: "Select Drip",
      //         agreement_heading: "Agreement",
      //         update_button: "Update",
      //       },
      //       confirmation_modal: {
      //         title: "Plan Configurations",
      //         update_button_text: "Update Configurations",
      //         sub_heading:
      //           "Are you sure you want to change default plan configuration?",
      //         info_card_heading: "Configuration Update Cautions:",
      //         caution_one:
      //           "The selected currency and payment gateway will be applied to all existing, as well as new plans created in the future.",
      //         caution_two:
      //           "The updated Portals, Programmes/Drip, and Agreement Forms settings will be applied automatically to all future plans.",
      //         caution_three:
      //           "These updates ensure that future plans will follow the latest configurations, providing a consistent experience for users and participants.",
      //         caution_four:
      //           "Please note, existing payments and subscriptions will not be affected by these configuration changes.",
      //       },
      //     },
      //   },
      //   website_pages: {
      //     ...tempDefaultContentObj.website_pages,
      //     payment_plans: {
      //       ...tempDefaultContentObj.website_pages.payment_plans,
      //       add_edit_payment_plan: {
      //         ...tempDefaultContentObj.website_pages.payment_plans
      //           .add_edit_payment_plan,
      //         plan_basic_partition_heading: "Plan Basics",
      //         payment_preferences_partition_heading: "Payment Preferences",
      //         plan_tax_partition_heading: "Plan Tax",
      //         plan_commission_partition_heading: "Plan Commission",
      //       },
      //       menu_items: {
      //         ...tempDefaultContentObj.website_pages.payment_plans.menu_items,
      //         manage_website_pages: "Manage Website Pages",
      //         manage_upsell_plan: "Manage Upsell Plans",
      //         manage_plan_access: "Manage Plan Access",
      //       },

      //       // new pairss
      //       manage_web_pages: {
      //         title: "Manage Website Pages",
      //         list_heading: "Website Pages",
      //         add_button_text: "Add Website Pages",
      //         update_button_text: "Update",
      //         select_page_modal_heading: "Select Website Pages",
      //         select_page_modal_record_not_found:
      //           "No website pages exist to add in plan.",
      //         select_page_modal_submit_button: "Submit",
      //         select_page_modal_cancel_button: "Cancel",
      //         info_tooltip:
      //           "Manage Website Pages allows you to link specific website pages to the selected plan. By assigning pages, you define where and how this plan will be applied across your website. Use this feature to ensure the plan is tailored to the pages that require it.",
      //       },
      //       manage_plan_access: {
      //         title: "Manage Plan Access",
      //         programmes_heading: "Programmes",
      //         drip_heading: "Drip",
      //         add_programme_drip_button_text: "Add Programmes/Drip",
      //         portals_heading: "Portals",
      //         add_portal_button_text: "Add Portals",
      //         updating_button_text: "Updating...",
      //         update_button_text: "Update",
      //         info_tooltip:
      //           "Manage plan's access of programs and portals based on your requirements.",
      //       },
      //       manage_upsell_plans: {
      //         title: "Manage Upsell Plans",
      //         list_heading: "Upsell Plans",
      //         add_button_text: "Add Upsell Plans",
      //         update_button_text: "Update",
      //         select_plan_modal_heading: "Select Upsell Plans",
      //         select_plan_modal_record_not_found:
      //           "No payment plans are available to add as an upsell plan.",
      //         select_plan_modal_submit_button: "Submit",
      //         select_plan_modal_cancel_button: "Cancel",
      //         info_tooltip:
      //           "Link upsell plans to expand the features of the selected plan.",
      //       },
      //     },
      //   },
      //   breadcrumbs: {
      //     ...tempDefaultContentObj.breadcrumbs,
      //     website_pages: {
      //       ...tempDefaultContentObj.breadcrumbs.website_pages,
      //       manage_web_pages: "Manage Website Pages",
      //       manage_plan_access: "Manage Plan Access",
      //       manage_upsell_plans: "Manage Upsell Plans",
      //     },
      //     plan_default_configurations: "Plan Default Configurations",
      //   },
      // };
      // setDefaultContent(tempDefaultContentObj);
      // localStorage.setItem(
      //   "defaultContent",
      //   JSON.stringify(tempDefaultContentObj)
      // );
      setDefaultContent(result?.coach_static_content);
      localStorage.setItem(
        "defaultContent",
        JSON.stringify(result?.coach_static_content)
      );
      if (
        result?.coach_nav_bar_content &&
        Object.keys(result?.coach_nav_bar_content).length != 0
      ) {
        localStorage.setItem(
          "navbarContent",
          JSON.stringify(result?.coach_nav_bar_content)
        );
        setNavbarContent(result?.coach_nav_bar_content);
      }
      if (
        result?.client_nav_bar_content &&
        Object.keys(result?.client_nav_bar_content).length != 0
      ) {
        localStorage.setItem(
          "clientNavbarContent",
          JSON.stringify(result?.client_nav_bar_content)
        );
        setClientNavbarContent(result?.client_nav_bar_content);
      }
      setDefaultLanguage(result?.configuration?.default_language);
      handleConsultantData(result);
      handleNavItmes(navItems);
      handleCoachId(result?.configuration.coach_id);
      handleCoachList(result.coach_array);
      handleGraphics(result?.configuration.coach_dummy_content);
      START_SOCKET_FORCEFULLY(result.coach._id);
      handleAdminTimeZone(result.configuration.admin_timezone);
      handleCoachTimeZone(result.configuration.time_zone);

      getCurrencyList();
      getCourseCurrencyList();
    }
  };

  const handleGetProjectData = async () => {
    const resp = await project_configuration_api(coachId);
    if (resp.code === 200) {
      localStorage.setItem(
        "project_data",
        JSON.stringify(resp.configuration.project_configuration)
      );
      if (
        resp.configuration.project_configuration.stripe_configuration
          .public_key ||
        resp.configuration.project_configuration.stripe_configuration
          .sandbox_public_key
      ) {
        setStripeKeysExist(true);
      }
      handleProjectData(resp.configuration.project_configuration);
    }
    return resp;
  };

  const getCurrencyList = async () => {
    let result = await currencyApi();
    if (result.code == 200) {
      setCurrencyList(result.currency_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getCourseCurrencyList = async () => {
    let result = await currencyApicourses();
    if (result.code == 200) {
      setCourseCurrencyList(result.currency_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const convertedTimeInUserTimeZone = (dateString, format = "") => {
    return get_date_with_user_time_zone(
      dateString,
      format ?? "YYYY-MM-DD HH:mm:ss",
      coachTimeZone,
      adminTimeZone
    );
  };

  const convertedTimeInUserTimeZoneFromNow = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        coachTimeZone,
        adminTimeZone
      )
    ).fromNow();
  };

  const convertedTimeInUserTimeZoneFromCalender = (dateString) => {
    return moment(
      get_date_with_user_time_zone(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        coachTimeZone,
        adminTimeZone
      )
    ).calendar();
  };

  function getNavbarContent(navItem) {
    let label = navItem?.option_label;
    if (navbarContent && navbarContent[navItem?.option_value]) {
      label = navbarContent[navItem.option_value];
    }
    return label;
  }

  const getClientNavbarContent = (navItem) => {
    let label = navItem?.option_label;
    if (clientNavbarContent && clientNavbarContent[navItem?.option_value]) {
      label = clientNavbarContent[navItem?.option_value];
    }
    return label;
  };

  const collection = {
    coachList,
    setCoachList,
    handleCoachList,
    navItems,
    setNavItems,
    handleNavItmes,
    dynamicCoachId,
    setDynamicCoachId,
    handleDynamicCoach,
    consultantData,
    handleConsultantData,
    handleRemoveConsultantMetaData,
    generalData,
    projectData,
    handleProjectData,
    domainData,
    handleDomainData,
    handleGetProjectData,
    stripeKeysExist,
    setStripeKeysExist,
    handleGeneralData,
    handleCoachId,
    coachId,
    coachInfo,
    handleCoachInfo,
    adminTimeZone,
    setAdminTimeZone,
    coachTimeZone,
    setCoachTimeZone,
    handleCoachTimeZone,
    handleSetUserInfo,
    userInfo,
    getUserInfoDetails,
    teamCoachId,
    getProfile,
    getCardDetail,
    consultantId,
    teamType,
    memberType,
    setUserInfo,
    consultantInfo,
    cardDetail,
    sandboxCachCard,
    handleSetCard,
    handleSetCoachCard,
    setCardDetail,
    setSandboxCoachCard,
    handleConsultantInfo,
    // currency List
    currencyList,
    setCurrencyList,
    courseCurrencyList,
    setCourseCurrencyList,

    getCurrencyList,
    getCourseCurrencyList,
    handleAdminTimeZone,
    convertedTimeInUserTimeZoneFromNow,
    convertedTimeInUserTimeZoneFromCalender,
    convertedTimeInUserTimeZone,
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
    openCardModal,
    setOpenCardModal,
    stripePaymentMode,
    setStripePaymentMode,
    handleGraphics,
    coachGraphics,
    open,
    setOpen,
    defaultContent,
    setDefaultContent,
    isLnaguageChange,
    setIsLanguageChange,
    defaulLanguagae,
    setDefaultLanguage,
    languageListing,
    setLanguageListing,
    getCoachLanguageListing,
    isLanguageUpdating,
    setIsLanguageUpdating,
    getNavbarContent,
    navbarContent,
    getClientNavbarContent,
    inlineEditorRef,
    isInteractingWithEditor,
    setInteractingWithEditor,
  };

  useEffect(() => {
    // console.log(socket, "--socket");
    if (
      userInfo?._id &&
      !isConnected &&
      typeof socket === "object" &&
      Object.keys(socket).length > 0
    ) {
      socket.emit("live_event_room", userInfo._id);
      setIsConnected(true);
    }
    return () => {};
  }, [userInfo, isConnected]);

  // useEffect(() => {
  //   userInfo.is_executive = true;
  // }, [userInfo]);
  // console.log(socket, "i am socket");
  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
