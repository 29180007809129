import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get_site_setting } from "src/DAL/Payment/Payment";
// import { useSnackbar } from 'notistack';

const PGIModeContext = React.createContext();

export const usePGIMode = () => useContext(PGIModeContext);
export function ContextPGIMode({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */
  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  const [mode, setMode] = React.useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [stripePublicKey, setStripePublicKey] = useState("");

  const getStripePublicKey = async () => {
    const result = await get_site_setting();
    if (result.code === 200) {
      // Testing or live keys
      if (result.site_setting.stripe_mode === "sandBox") {
        setStripePublicKey(result.site_setting.sandBox_publish_key);
      } else {
        setStripePublicKey(result.site_setting.live_publish_key);
      }
    } else {
      alert(result.message);
    }
  };
  // useEffect(() => {
  //   getStripePublicKey();
  // }, []);
  /* ------------------------------------------------------
  ------------------/ Hooks Functions /--------------------
  ------------------------------------------------------- */
  const handleChangeMode = (value) => {
    setMode(value);
  };
  const handleStripeKey = (value) => {
    if (value.mode === "sandbox") {
      setStripePublicKey(value.sandbox_publish_key);
    } else {
      setStripePublicKey(value.live_publish_key);
    }
    // setStripePublicKey(value.live_publish_key);
  };
  useEffect(() => {}, []);

  const collection = {
    mode,
    handleChangeMode,
    breadCrumbs,
    setBreadCrumbs,
    stripePublicKey,
    handleStripeKey,
  };
  return (
    <PGIModeContext.Provider value={collection}>
      {children}
    </PGIModeContext.Provider>
  );
}
