import { invokeApi } from "../../bl_libs/invokeApi";
const user_token = localStorage.getItem("token");
// const user_token =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWZiNjg0MzFiOTIxZDM1NjQwNmQ4MmQiLCJsb2dpbl9ieSI6ImFkbWluX3VzZXIiLCJpYXQiOjE2NjY2MDYzMTJ9.DBr2kZVj1cgEJzqQVbZzBAXoH-Ptu4hvSQldanuApgE";
export const websiteProgrammesListApi = async (id) => {
  let api_path = `api/website_program`;
  if (id) {
    api_path = `api/website_program/list_by_sale_page/${id}`;
  }
  const requestObj = {
    path: api_path,
    method: "GET",
    headers: {
      "x-sh-auth": user_token,
    },
  };
  return invokeApi(requestObj);
};

export const website_programmes_detail_api = async (id) => {
  const requestObj = {
    path: `api/website_program/get_program/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": user_token,
    },
  };
  return invokeApi(requestObj);
};
export const activeProgrammesCategoryListApi = async (data) => {
  const requestObj = {
    path: `api/program_category/active_list`,
    method: "GET",
    headers: {
      "x-sh-auth": user_token,
    },
  };
  return invokeApi(requestObj);
};
export const addWebsiteProgrammesApi = async (data) => {
  const requestObj = {
    path: `api/website_program`,
    method: "POST",
    headers: {
      "x-sh-auth": user_token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editWebsiteProgrammesApi = async (data, id) => {
  const requestObj = {
    path: `api/website_program/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": user_token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const defaultNav = async (data, id) => {
  const requestObj = {
    path: `api/programme_configuration/update_programme_default_nav_items/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const programNav = async (data, id) => {
  const requestObj = {
    path: `api/programme/update_programme_nav_items/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getDefaultNavProgram = async (id) => {
  const requestObj = {
    path: `api/coach_configuration/detail_coach_configuration/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getDefaultNavItems = async (id) => {
  const requestObj = {
    path: `api/programme_configuration/get_default_programme_nav_items/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getProgramConfiguration = async (id) => {
  const requestObj = {
    path: `api/programme_configuration/get_programme_configuration/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteWebsiteProgrammesApi = async (id) => {
  const requestObj = {
    path: `api/website_program/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": user_token,
    },
  };
  return invokeApi(requestObj);
};
